import { useState, useRef, useEffect } from 'react';
import './index.scss';
import BlackDialog from 'self-component/DialogSelf';
import { tipsTransaction, sendTipsVerifyCode } from 'utils/api';
import { Input, Form, Button } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { toThousands } from 'utils/commonUtils';
import { Deposit_vault_off, Deposit_vault_on, Withdraw_vault_off, Withdraw_vault_on, Withdraw_vault_green, USDT_icon } from 'static/images';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { SET_USDT_ACCOUNT, SET_2FA_VERIFY, PROFILE_ID, MENU_OPEN } from 'store/actions';
import { getUserAccount } from 'store/actions/userAction';
import { useNavigate } from 'react-router-dom';

const VERIFY_TYPE = {
  MAIL: 1,
  TWO_FA: 2
};

const TIP = (props) => {
  const { open, onClose } = props;
  const navigate = useNavigate();

  const [tipForm] = Form.useForm();

  const usdtAccount = useSelector((state) => state.customization.usdtAccount);
  const userInfor = useSelector((state) => state.customization.userInfor);

  const [loading, setLoading] = useState(false);

  const [verifyType, setVerifyType] = useState(VERIFY_TYPE.MAIL);
  const [isCountDown, setIsCountDown] = useState(false);
  const [countDown, setCountDown] = useState(120);
  const timer = useRef(null);

  const dispatch = useDispatch();

  const handleCancel = () => {
    onClose && onClose();
    tipForm.resetFields();
  };
  const handleSubit = async () => {
    setLoading(true);
    try {
      const formValue = await tipForm.validateFields();
      const data = {
        ...formValue,
        amount: Number(formValue.amount),
        userId: Number(formValue.userId),
        type: verifyType
      };
      const res = await tipsTransaction(data);

      dispatch(getUserAccount());
      enqueueSnackbar('success', { autoHideDuration: 2000, variant: 'success' });
      setLoading(false);

      clearInterval(timer.current);
      setCountDown(120);
      setIsCountDown(false);
    } catch (error) {
      setLoading(false);
      if (error.msg) enqueueSnackbar(error.msg, { autoHideDuration: 2000, variant: 'error' });
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', flexGrow: 1 }}>
      <div>
        {
          <span
            style={{
              fontFamily: `"Geogrotesque Wide", sans-serif`,
              fontSize: '16px',
              color: '#576788',
              marginBottom: '5px'
            }}
          >
            {label}
          </span>
        }
        {required ? <span style={{ color: 'RGBA(239, 182, 30, 1)' }}> *</span> : ''}
      </div>

      {label == 'Amount' && (
        <span
          style={{ color: 'RGBA(239, 182, 30, 1)', fontFamily: `"Geogrotesque Bl"`, marginLeft: 'auto', flexGrow: 1, textAlign: 'right' }}
        >
          ${toThousands(usdtAccount)}
        </span>
      )}
      {label == 'VIA EMAIL' && !!userInfor.twoFa && (
        <span
          style={{ color: 'RGBA(239, 182, 30, 1)', fontFamily: `"Geogrotesque wide"`, marginLeft: 'auto', flexGrow: 1, textAlign: 'right' }}
          onClick={() => setVerifyType(VERIFY_TYPE.TWO_FA)}
        >
          Two Factor
        </span>
      )}
      {label == 'Two Factor' && (
        <span
          style={{ color: 'RGBA(239, 182, 30, 1)', fontFamily: `"Geogrotesque wide"`, marginLeft: 'auto', flexGrow: 1, textAlign: 'right' }}
          onClick={() => setVerifyType(VERIFY_TYPE.MAIL)}
        >
          VIA EMAIL
        </span>
      )}
    </div>
  );

  const handlekeyDown = (e) => {
    if (e.key === 'Enter') return;
  };

  const handleMax = () => {
    tipForm.setFieldsValue({ amount: usdtAccount });
  };

  const handleSendMail = async () => {
    if (isCountDown) return;
    try {
      // if(!email) enqueueSnackbar('Please bind your email', { autoHideDuration: 2000 });
      const formValue = await tipForm.validateFields(['userId', 'amount', 'password']);
      sendTipsVerifyCode({ ...formValue, type: verifyType });
      setIsCountDown(true);
      // if (!isCountDown) {
      let num = 120;
      timer.current = setInterval(() => {
        if (num > 0) {
          num--;
          setCountDown(num);
        } else {
          num = 120;
          setCountDown(num);
          clearInterval(timer.current);
          setIsCountDown(false);
        }
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };

  const handleTwoFa = () => {
    dispatch({ type: SET_2FA_VERIFY, verify_2fa: true });
  };
  const goHistory = () => {
    handleCancel();
    navigate('/game_profile');
    window.localStorage.setItem('PROFILE_ID', '4');
    dispatch({ type: PROFILE_ID, profileId: '4' });
    dispatch({ type: MENU_OPEN, id: [] });
    localStorage.setItem('MENU_ID', '');
  };
  return (
    <>
      <BlackDialog open={open} onClose={handleCancel} width={450} title={<div style={{ display: 'flex' }}>TIP</div>}>
        <div className="vault-content">
          <div style={{ marginTop: '20px' }}>
            <Form
              form={tipForm}
              layout="vertical"
              requiredMark={customizeRequiredMark}
              style={{ marginTop: '16px' }}
              initialValues={{ amount: '0.00' }}
            >
              <Form.Item
                label="Tip to"
                name="userId"
                required
                rules={[{ required: true, message: 'UID is require' }]}
                style={{ marginBottom: '10px' }}
              >
                <Input size="large" placeholder="Please enter UID" autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Amount"
                required
                rules={[
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject('Amount is require');
                      }
                      if (value <= 0) {
                        return Promise.reject('Amount cannot be less than zero');
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                name="amount"
                style={{ marginBottom: '10px' }}
              >
                <Input
                  size="large"
                  placeholder="Please enter withdraw amount"
                  autoComplete="off"
                  suffix={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={USDT_icon} alt="" srcset="" width={20} />
                      <LoadingButton
                        disableRipple
                        onClick={() => handleMax()}
                        sx={{
                          height: '30px',
                          width: '60px',
                          margin: '0 auto',
                          display: 'flex',
                          justifyContent: 'center',
                          background: '#FFE619',
                          fontSize: '16px',
                          color: '#302B05',
                          borderRadius: '3px',
                          fontFamily: 'Geogrotesque Bl',
                          lineHeight: '16px',
                          marginLeft: '30px',
                          '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                        }}
                      >
                        Max
                      </LoadingButton>
                    </div>
                  }
                />
              </Form.Item>
              <Form.Item
                label="Password"
                required
                name="password"
                rules={[{ required: true, message: 'Password is require' }]}
                onKeyDown={handlekeyDown}
                style={{ marginBottom: '10px' }}
              >
                <Input.Password
                  size="large"
                  placeholder="********"
                  autoComplete="off"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone style={{ color: '#fff' }} /> : <EyeInvisibleOutlined style={{ color: '#fff' }} />
                  }
                />
              </Form.Item>
              {
                <Form.Item
                  label={verifyType === VERIFY_TYPE.MAIL ? 'VIA EMAIL' : 'Two Factor'}
                  name="code"
                  rules={[{ required: true, message: 'Code is require' }]}
                  onKeyDown={handlekeyDown}
                >
                  <Input
                    size="large"
                    placeholder={verifyType === VERIFY_TYPE.MAIL ? 'Please enter email code' : 'Please enter two factor code'}
                    autoComplete="off"
                    suffix={
                      verifyType === VERIFY_TYPE.MAIL && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <LoadingButton
                            disableRipple
                            onClick={() => handleSendMail()}
                            sx={{
                              height: '30px',
                              width: '60px',
                              margin: '0 auto',
                              display: 'flex',
                              justifyContent: 'center',
                              background: '#FFE619',
                              fontSize: '16px',
                              color: '#302B05',
                              borderRadius: '3px',
                              fontFamily: 'Geogrotesque Bl',
                              lineHeight: '16px',
                              marginLeft: '30px',
                              '&:hover': { background: '#FFEF6D', boxShadow: '0px 0px 6px 0px #FFE936' }
                            }}
                          >
                            {isCountDown ? countDown + 's' : 'SEND'}
                          </LoadingButton>
                        </div>
                      )
                    }
                  />
                </Form.Item>
              }
            </Form>

            <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
              <LoadingButton
                onClick={handleCancel}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#5583AE',
                  borderRadius: '5px',
                  background: '#152D43',
                  flex: 1,
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`
                }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={() => handleSubit()}
                loading={loading}
                disableRipple
                sx={{
                  fontSize: '14px',
                  color: '#3E3A19',
                  borderRadius: '5px',
                  background: '#FFE619',
                  flex: 1,
                  height: '30px',
                  lineHeight: '30px',
                  fontFamily: `"Geogrotesque Bl", sans-serif`,
                  '&:hover': {
                    backgroundColor: '#FFEF6D',
                    boxShadow: '0px 0px 6px 0px #FFE936'
                  }
                }}
              >
                SEND TIP
              </LoadingButton>
            </div>

            {!!!userInfor.twoFa && (
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px' }}>
                <div style={{ color: '#5F7096', marginBottom: '10px' }}>lmprove your account security with Two-Factor Authentication</div>
                <LoadingButton
                  onClick={handleTwoFa}
                  disableRipple
                  sx={{
                    fontSize: '14px',
                    color: '#5583AE',
                    borderRadius: '5px',
                    background: '#152D43',
                    // width: '84px',
                    minWidth: '100%',
                    height: '30px',
                    lineHeight: '30px',
                    fontFamily: `"Geogrotesque Bl", sans-serif`
                    // boxShadow:
                    //   '0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 0px 0px rgba(240,108,0,1), 0px 0px 2px 0px rgba(240,108,0,1), 0px 0px 3px 0px rgba(240,108,0,1), 0px 0px 5px 0px rgba(240,108,0,1), 0px 0px 10px 0px rgba(240,108,0,1)'
                  }}
                >
                  Enable 2FA
                </LoadingButton>
              </div>
            )}
            <div
              onClick={goHistory}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '10px',
                cursor: 'pointer',
                textDecoration: 'underline',
                fontFamily: `"Geogrotesque Bl", sans-serif`,
                color: '#5F7096'
              }}
            >
              Transactions History
            </div>
          </div>
        </div>
      </BlackDialog>
    </>
  );
};

export default TIP;
