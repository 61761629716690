import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
// dashboard routing
const Lottery = Loadable(lazy(() => import('views/lottery')));
const Home = Loadable(lazy(() => import('views/home')));
// const BinaryOption = Loadable(lazy(() => import('views/binaryOption')));
const OneBinaryOption = Loadable(lazy(() => import('views/oneBinaryOption')));
const VerifyFairness = Loadable(lazy(() => import('views/lottery/verifyFairness')));
const CrashVerifyFairness = Loadable(lazy(() => import('views/crash/verifyFairness')));
const PlinkoVerifyFairness = Loadable(lazy(() => import('views/Games/Plinko/verifyFairness')));
const Crash = Loadable(lazy(() => import('views/crash')));
const BlackJack = Loadable(lazy(() => import('views/blackJack')));
const Profile = Loadable(lazy(() => import('views/profile')));
const Transaction = Loadable(lazy(() => import('views/profile/transactions')));
const Plinko = Loadable(lazy(() => import('views/Games/Plinko/index')));
const RaffleNew = Loadable(lazy(() => import('views/raffleNew/index')));
const Roulette = Loadable(lazy(() => import('views/roulette/')));
const ThreeGame = Loadable(lazy(() => import('views/threeGame')));
const Slots = Loadable(lazy(() => import('views/menuGameList/slots')));
const LiveCasino = Loadable(lazy(() => import('views/menuGameList/liveCasino')));

const GameShow = Loadable(lazy(() => import('views/menuGameList/gameShow')));
const Blackjack = Loadable(lazy(() => import('views/menuGameList/blackjack')));
const RoulettePP = Loadable(lazy(() => import('views/menuGameList/roulette')));
const Favorite = Loadable(lazy(() => import('views/menuGameList/favorite')));
const Baccarat = Loadable(lazy(() => import('views/menuGameList/baccarat')));
const Challenge = Loadable(lazy(() => import('views/menuGameList/challenge')));
const BitGame = Loadable(lazy(() => import('views/menuGameList/21bitsGame')));
const TradeView = Loadable(lazy(() => import('views/trade')));
const UpDown = Loadable(lazy(() => import('views/upDown')));
const Claim = Loadable(lazy(() => import('views/claim/afterLogin/index')));

const HelpCenter = Loadable(lazy(() => import('views/footerInfo/helpCenter')));
const License = Loadable(lazy(() => import('views/footerInfo/license')));
const AmlPolicy = Loadable(lazy(() => import('views/footerInfo/amlPolicy')));
const Terms = Loadable(lazy(() => import('views/footerInfo/terms')));
const Privacy = Loadable(lazy(() => import('views/footerInfo/privacy')));

const AirDrop = Loadable(lazy(() => import('views/airdrop')));
const Sports = Loadable(lazy(() => import('views/sports/sportsItem')));
const Esports = Loadable(lazy(() => import('views/sports/esportsItem')));
const Provides = Loadable(lazy(() => import('views/provides')));
const Pragmatic = Loadable(lazy(() => import('views/provides/pragmatic')));
const Promotions = Loadable(lazy(() => import('views/promotions')));
const PromotionsDri = Loadable(lazy(() => import('views/promotions/description')));
const PP_active_dir = Loadable(lazy(() => import('views/promotions/description/3')));
const PP_active_4 = Loadable(lazy(() => import('views/promotions/description/4')));
const Active_5 = Loadable(lazy(() => import('views/promotions/description/5')));
const Race = Loadable(lazy(() => import('views/race')));
const DepositBouns = Loadable(lazy(() => import('views/promotions/depositBouns')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/game_provides',
      element: <Provides />
    },
    {
      path: '/game_race',
      element: <Race />
    },
    {
      path: '/game_promotions',
      element: <Promotions />
    },
    {
      path: '/game_promotions/description',
      element: <PromotionsDri />
    },
    {
      path: '/game_promotions/description/3',
      element: <PP_active_dir />
    },
    {
      path: '/game_promotions/description/4',
      element: <PP_active_4 />
    },
    {
      path: '/game_promotions/description/5',
      element: <Active_5 />
    },
    {
      path: '/game_provides/:name/:id/:brand',
      element: <Pragmatic />
    },
    // {
    //   path: '/game_raffle',
    //   element: <Lottery />
    // },
    {
      path: '/game_trade',
      element: <TradeView />
    },
    {
      path: '/game_upDown',
      element: <UpDown />
    },
    {
      path: '/game_sports',
      element: <Sports />
    },
    {
      path: '/game_esports',
      element: <Esports />
    },
    {
      path: '/game_crash',
      element: <Crash />
    },
    {
      path: '/game_hustler',
      element: <BlackJack />
    },
    {
      path: '/game_profile',
      element: <Profile />
    },
    {
      path: '/game_transaction',
      element: <Transaction />
    },
    {
      path: '/game_plinko',
      element: <Plinko />
    },
    {
      path: '/third-game',
      element: <ThreeGame />
    },

    // {
    //   path: '/prediction',
    //   element: <BinaryOption />
    // },
    // {
    //   path: '/game_prediction',
    //   element: <OneBinaryOption />
    // },
    {
      path: '/provably-fair',
      element: <VerifyFairness />
    },
    {
      path: '/x-crash/provably-fair',
      element: <CrashVerifyFairness />
    },
    {
      path: '/x-plinko/provably-fair',
      element: <PlinkoVerifyFairness />
    },
    // {
    //   path: '/new-raffle',
    //   element: <RaffleNew />
    // },
    {
      path: '/21bits-originals',
      element: <BitGame />
    },
    {
      path: '/game_roulette',
      element: <Roulette />
    },
    {
      path: '/slots',
      element: <Slots />
    },
    {
      path: '/live_casino',
      element: <LiveCasino />
    },
    {
      path: '/virtual-sport',
      element: <GameShow />
    },
    {
      path: '/blackjack',
      element: <Blackjack />
    },
    {
      path: '/roulette-pp',
      element: <RoulettePP />
    },
    {
      path: '/favorite',
      element: <Favorite />
    },
    {
      path: '/baccarat',
      element: <Baccarat />
    },
    {
      path: '/challenge',
      element: <Challenge />
    },
    {
      path: '/game_claim',
      element: <Claim />
    },
    {
      path: '/info/game_help-centre',
      element: <HelpCenter />
    },
    {
      path: '/info/game_licence',
      element: <License />
    },
    {
      path: '/info/game_AML-Policy',
      element: <AmlPolicy />
    },
    {
      path: '/info/game_terms',
      element: <Terms />
    },
    {
      path: '/info/game_privacy',
      element: <Privacy />
    },
    {
      path: '/game_airdrop',
      element: <AirDrop />
    },
    {
      path: '/game_depositBouns',
      element: <DepositBouns />
    }

    // { path: '*', element: <Navigate to="/prediction" /> },
  ]
};

export default MainRoutes;
