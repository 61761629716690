import React, { useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';

import { CssBaseline, StyledEngineProvider,useMediaQuery } from '@mui/material';
// routing
import Routes from 'routes';
import { useAccount } from 'wagmi';

// defaultTheme
import themes from 'themes';
import { useTheme } from '@mui/material/styles';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { setEvents } from 'utils/setEvents';

//noice
import { SnackbarProvider } from 'notistack';

import { useDispatch, useSelector } from 'react-redux';
import {
  SET_ADDRESS,
  SET_LOGIN_RESIGN_OPEN,
  SHOW_NOTICE,
  BET_RECORD_LIST,
  BET_ALL_RECORD_LIST,
  SET_TRADE_LIST,
  SET_WEBSCOKET_LOGIN,
  SET_SPORT_VIEW,
  BET_RECORD_HISTORY_LIST,
  SET_2FA_VERIFY,
  SET_IP_VERIFIY,
  MOBILE_MENU_OPEN
} from 'store/actions';
import { getCurrencyList } from 'utils/api';
import { useNavigate } from 'react-router-dom';
import { getFavoriteGameList, getUserInfor } from 'store/actions/userAction';
import { ppActivity } from 'utils/api/threeGame';

import { useLocation, useSearchParams } from 'react-router-dom';
import LoginAndResiger from 'ui-component/loginAndResiger';
import NoiceModel from 'ui-component/noiceModel';
import IpVerifiy from 'ui-component/extended/Cashier/IpVerifiy';
import GlobalLoading from 'ui-component/globalLoading';
import { parseQueryString } from 'utils/utilTools';
import { tradeSymbolsList } from 'utils/api/index';
import { getFps, getNetworkIp } from 'utils/commonUtils';
import Verify_2fa from 'ui-component/extended/verify_2fa';
// ==============================|| APP ||============================== //

const App = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { address } = useAccount();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useLocation();
  const auth_code = searchParams.get('ref');
  const codeObj = parseQueryString(params.pathname + '');
  const code = searchParams.get('code');
  const token = window.localStorage.getItem('token');
  const mapGame = window.localStorage.getItem('mapGameList');
  const mapGameList = JSON.parse(mapGame || '[]');
  useEffect(() => {
    getTradeList();
    getppActivityGameList();
    getWithDrawCoinType();
    getFps(dispatch);
    if (token) {
      dispatch(getUserInfor());
    }
  }, []);
  useEffect(() => {
    dispatch({ type: MOBILE_MENU_OPEN, mobileMenuOpen: !matchDownMd });
  }, [matchDownMd]);
  useEffect(() => {
    dispatch({ type: SET_SPORT_VIEW, pathName: params.pathname });
  }, [params]);
  useEffect(() => {
    if (customization.userInfor) {
      dispatch(getFavoriteGameList({ uid: customization.userInfor.id + '', name: '', provider: 0 }));
    }
    initWebSocketTrade();
    return () => {
      starx.disconnect();
    };
  }, [token]);
  const getppActivityGameList = async () => {
    try {
      const { data } = await ppActivity({ activity_index: [1] });
      const PP_ACTIVE_LIST = JSON.stringify(data);
      window.localStorage.setItem('PP_ACTIVE_LIST', PP_ACTIVE_LIST);
    } catch (error) {}
    // setFilterGameList(list);
  };
  async function getWithDrawCoinType() {
    const { data } = await getCurrencyList();
    const obj = {};
    data.map((item) => {
      obj[item.id] = item;
    });
    window.localStorage.setItem('COINTYPE', JSON.stringify(obj));
  }
  function initWebSocketTrade() {
    starx.init({ host: process.env.REACT_APP_NODE_SOCKET_URL, port: process.env.REACT_APP_NODE_SOCKET_PORT, path: '' }, (res) => {
      starx.request('Manager.Login', { token: customization.token }, (res) => {
        console.log(res, 'newList');
        dispatch({ type: SET_WEBSCOKET_LOGIN, isLogin: Math.random() * 100 });
        const { bet_histroy } = res;
        dealList(bet_histroy);
      });
      // starx.on('cmd_trademsg', (res) => {
      //   dispatch({ type: SET_TRADE_LIST, tradeList: res.trades });
      // });
    });
  }

  function dealList(data) {
    console.log(data, 'mapGameList');
    const list = data.map((item) => {
      const { game_id, game_name, suffix } = item.bet;
      return {
        ...item,
        game_name: game_name || '',
        key: Math.random().toString().slice(3, 8),
        suffix,
        game_id
      };
    });
    // dispatch({ type: BET_RECORD_LIST, betRecordList: list });
    // dispatch({ type: BET_ALL_RECORD_LIST, betAllRecordList: list });
    dispatch({ type: BET_RECORD_HISTORY_LIST, betHistory: list });
  }
  useEffect(() => {
    window.localStorage.setItem('address', address);
    if (auth_code) {
      window.localStorage.setItem('auth_code', auth_code || '');
      setEvents(5);
    }

    if (code) {
      window.localStorage.setItem('invit_code', code || '');
      if (code === 'bitcoinist') {
        // /code=bitcoinist
        gtag('event', 'bitcoinist_first_view');
      }
    }

    dispatch({
      type: SET_ADDRESS,
      address: address
    });
    return () => {};
  }, [address]);

  async function getTradeList() {
    const res = await tradeSymbolsList();
    const { symbols } = res.data;
    const list = symbols.map((item) => {
      return {
        Symbol: item,
        Percent: 0,
        LastPrice: 0,
        BaseVolume: 0,
        QuoteVolume: 0,
        HighPrice: 0,
        LowPrice: 0
      };
    });
    dispatch({ type: SET_TRADE_LIST, tradeList: list });
  }
  return (
    <GlobalLoading>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <SnackbarProvider maxSnack={1} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
              <LoginAndResiger
                open={customization.openLoginAndResgin}
                type={customization.type}
                onClose={() => dispatch({ type: SET_LOGIN_RESIGN_OPEN, openLoginAndResgin: false })}
              />
              <NoiceModel
                open={customization.noticeOpen}
                content={customization.noticeContent}
                onClose={() => dispatch({ type: SHOW_NOTICE, noticeOpen: false })}
              />
              <Verify_2fa open={customization.verify_2fa} onClose={() => dispatch({ type: SET_2FA_VERIFY, verify_2fa: false })} />
              <IpVerifiy open={customization.ipVerifiy} onClose={() => dispatch({ type: SET_IP_VERIFIY, ipVerifiy: false })} />
              {/* <div style={{position:'fixed',top:'50px',right:'20px',zIndex:'9999'}}>fps:  {customization.fps}</div> */}
              <Routes />
              {/* <Footer/> */}
            </SnackbarProvider>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </GlobalLoading>
  );
};

export default App;
